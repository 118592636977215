import { FC, useContext } from 'react'
import cn from 'classnames'
// local libs
import { ThemeContext } from '../Theme'
import { MoonIcon, SunIcon } from 'src/shared/assets/icons'
// types
import { ThemeEnum } from 'src/shared/types/enums'
// styles
import styles from './ThemeSelect.module.scss'

export const ThemeSelect: FC = () => {
  const { themeValue, toggleTheme } = useContext(ThemeContext)

  return (
    <div
      onClick={toggleTheme}
      className={styles.themeSelect}
      role="switch"
      aria-label="Theme selection"
    >
      <button
        className={cn({
          [styles.buttonActive]: themeValue === ThemeEnum.light,
        })}
        aria-pressed={themeValue === ThemeEnum.light}
        aria-label="light theme"
      >
        <SunIcon
          className={cn(styles.themeIcon, {
            [styles.active]: themeValue === ThemeEnum.light,
          })}
        />
      </button>

      <button
        className={cn({ [styles.buttonActive]: themeValue === ThemeEnum.dark })}
        aria-pressed={themeValue === ThemeEnum.dark}
        aria-label="dark theme"
      >
        <MoonIcon
          className={cn(styles.themeIcon, {
            [styles.active]: themeValue === ThemeEnum.dark,
          })}
        />
      </button>
    </div>
  )
}
