type LocalKeyData = {
  ns: string
  key: string
}

export const getLocalKeyData = (localKey: string) => {
  const localKeyArray = localKey.split('.')

  return {
    ns: localKeyArray[1],
    // если ключ пустой, значит не по формату, возвращаем как есть
    key: localKeyArray.slice(2, 4).join('.') || localKey,
  } as LocalKeyData
}
