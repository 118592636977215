import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
// local libs
import { useClickOutside } from 'src/shared/hooks/useClickOutside'
import { Text } from 'src/shared/ui'
import { TextKind, TextSizes } from 'src/shared/ui/Text'
import { store } from 'src/app/store'
// styles
import styles from './LanguageSelect.module.scss'

export const LanguageSelect: FC<{ languageList: Array<Language> }> = ({
  languageList,
}) => {
  const {
    i18n: { changeLanguage, language },
  } = useTranslation()

  store.language.value = language

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const handleButtonClick = () => setIsDropdownOpen((v) => !v)

  useClickOutside(dropdownRef, () => setIsDropdownOpen(false))

  const handleLanguageClick = (lng: string) => {
    changeLanguage(lng)
    setIsDropdownOpen(false)
  }

  return (
    <div
      ref={dropdownRef}
      className={styles.dropdownContainer}
      aria-label="Language Select"
    >
      <button
        className={cn(styles.dropdownToggle, styles.languageItem, {
          [styles.dropdownToggleActive]: isDropdownOpen,
        })}
        onClick={handleButtonClick}
        aria-expanded={isDropdownOpen}
        aria-controls="language-dropdown"
      >
        <Text kind={TextKind.title} size={TextSizes.small}>
          {language?.toUpperCase()}
        </Text>
      </button>
      {isDropdownOpen && (
        <ul
          className={styles.languageDropdown}
          id="language-dropdown"
          role="menu"
        >
          {languageList
            .filter((v) => v.code !== language)
            .map((lng, index) => (
              <li
                key={index}
                onClick={() => handleLanguageClick(lng.code)}
                className={styles.languageItem}
                role="menuitem"
              >
                <Text kind={TextKind.title} size={TextSizes.small}>
                  {lng.code.toUpperCase()}
                </Text>
              </li>
            ))}
        </ul>
      )}
    </div>
  )
}
