export enum TextSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum TextKind {
  basic = 'basic',
  headline = 'headline',
  label = 'label',
  title = 'title',
}

type AsProp<E extends React.ElementType> = {
  as?: E
}

type BaseProps<E extends React.ElementType> = AsProp<E> &
  Omit<React.ComponentPropsWithRef<E>, keyof AsProp<E>>

export type PolymorphicComponentProps<
  E extends React.ElementType,
  P = unknown,
> = BaseProps<E> & P

export type TextProps = {
  size?: TextSizes
  kind?: TextKind
  className?: string
}
