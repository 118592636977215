import { FC } from 'react'

export const ChevronDown: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M8 12.12L0 4.12L1.12 3L8 9.88L14.88 3L16 4.12L8 12.12Z" />
    </svg>
  )
}
