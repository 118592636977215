export const isFontConfigValid = (
  x: FontConfig | undefined,
): x is Required<FontConfig> =>
  Boolean(x && 'mediumUrl' in x && 'regularUrl' in x)

const fontOptions: Record<keyof FontConfig, { weight: string; style: string }> =
  {
    regularUrl: {
      weight: '400',
      style: 'normal',
    },
    mediumUrl: {
      weight: '500',
      style: 'normal',
    },
  }

export const loadFonts = (fontConfig: Required<FontConfig>) => {
  const family = 'Custom'

  const promises = Object.entries(fontConfig).map(([key, url]) =>
    new FontFace(
      family,
      `url(${url})`,
      fontOptions[key as keyof FontConfig],
    ).load(),
  )

  Promise.all(promises)
    .then((loadedFonts) => {
      loadedFonts.forEach((x) => document.fonts.add(x))
      document.documentElement.style.setProperty(
        '--font-family',
        `${family}, sans-serif`,
      )
    })
    .catch((e) => console.error('Font load error', e))
}
