import { signal } from '@preact/signals-react'
import { DataContextValues } from './types'
import { DEFAULT_LANG_CODE } from '../config/consts'
import { StoredCard } from 'src/shared/types/cards'

const languageList = signal<Array<Language>>([])
const paymentData = signal<DataContextValues['paymentData']>(null)
const language = signal<string>(DEFAULT_LANG_CODE)
const activePaymentMethod =
  signal<DataContextValues['activePaymentMethod']>(null)
const selectedSavedCard = signal<StoredCard | null>(null)

export const store = {
  languageList,
  paymentData,
  language,
  activePaymentMethod,
  selectedSavedCard,
}
