import * as yup from 'yup'
import {
  validationInvalidFormatKey,
  validationMaxLengthKey,
  validationRequiredKey,
} from 'src/shared/constants'

export const emailValidationSchema = yup
  .string()
  .required(validationRequiredKey)
  .max(254, validationMaxLengthKey)
  .matches(/^.+@.+\..+$/, validationInvalidFormatKey)
  .matches(
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]{2,63}\.[a-zA-Z]{2,}$/,
    validationInvalidFormatKey,
  )
  .matches(/^.+@.+\..+$/, validationInvalidFormatKey)
  .test({
    message: validationInvalidFormatKey,
    test: function (value) {
      if (!value) return true
      const isValid = !value.includes('..')
      return isValid
    },
  })
  .test({
    message: validationInvalidFormatKey,
    test: function (value) {
      if (!value) return true
      const words = value.split('@')
      const userName = words[0]
      const isValid = /^[a-z0-9_-].*[a-z0-9_-]$/i.test(userName)
      return isValid
    },
  })
