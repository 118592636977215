// основные общие типы хранятся в types/app.d.ts, кроме enum
// так как появляются ошибки при runtime, нормального способа их там указать не найдено
export enum ThemeEnum {
  dark = 'dark',
  light = 'light',
}

export enum ResponseStatusEnum {
  DATA = 'DATA',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
