import { useContext, useEffect } from 'react'
import { API_URL } from 'src/app/config/api'
import { store } from 'src/app/store'
import { ThemeContext } from '../Theme'
import { isFontConfigValid, loadFonts } from './loadFonts'

const fallbackLogoValues: ImageConfig = {
  darkUrl: '/dark-theme-default-logo.png',
  lightUrl: '/light-theme-default-logo.png',
}

const fallbackFontsConfig: Required<FontConfig> = {
  mediumUrl: '/font/font-medium.woff2',
  regularUrl: '/font/font-regular.woff2',
}

const useBackgroundImage = () => {
  const { themeValue } = useContext(ThemeContext)

  const backgroundValues = store.paymentData.value?.terminalConfig?.background
  const rawBackgroundUrl = backgroundValues?.[`${themeValue}Url`]
  const preparedBackgroundUrl = !rawBackgroundUrl
    ? null
    : [API_URL, rawBackgroundUrl].join('')

  const backgroundUrl = preparedBackgroundUrl
    ? `url(${preparedBackgroundUrl})`
    : null
  return { backgroundUrl }
}

const useLogo = () => {
  const { themeValue } = useContext(ThemeContext)

  const fallbackLogoUrl = fallbackLogoValues[`${themeValue}Url`]

  const logoValues = store.paymentData.value?.terminalConfig?.logo
  const rawLogoUrl = logoValues?.[`${themeValue}Url`]
  const preparedLogoUrl = !rawLogoUrl ? null : [API_URL, rawLogoUrl].join('')

  const logoUrl = preparedLogoUrl || fallbackLogoUrl
  return { logoUrl }
}

const useFont = async () => {
  useEffect(() => {
    const fontsConfig = store.paymentData.value?.terminalConfig?.font

    if (isFontConfigValid(fontsConfig)) loadFonts(fontsConfig)
    else loadFonts(fallbackFontsConfig)
  }, [])
}

export const useCustomConfig = () => {
  useFont()
  const { backgroundUrl } = useBackgroundImage()
  const { logoUrl } = useLogo()
  return { backgroundUrl, logoUrl }
}
