import { FC } from 'react'
// local libs
import { Link, Text } from 'src/shared/ui'
// styles
import styles from './Footer.module.scss'
// types
import { TextKind, TextSizes } from 'src/shared/ui/Text'

export const Footer: FC = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerWrapper}>
        <div className={styles.footerLogo}>
          <img src="/pci-logo.svg" alt="pci logo" />
        </div>

        <Text
          as="div"
          kind={TextKind.label}
          size={TextSizes.large}
          className={styles.footerCopyright}
        >
          © 2023-2024
          <Link>IDS</Link>
        </Text>
      </div>
    </div>
  )
}
