import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PagesEnum } from 'src/app/routes'

export const ContactPageRedirect = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(PagesEnum.contactStore)
  }, [])

  return <></>
}
