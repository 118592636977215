import React from 'react'
import cn from 'classnames'
// local libs
import styles from './Text.module.scss'
// types
import type { PolymorphicComponentProps, TextProps } from './types'
import { TextKind, TextSizes } from './types'

export const Text = <E extends React.ElementType = 'span'>({
  as,
  children,
  className,
  size = TextSizes.medium,
  kind = TextKind.basic,
  ...restProps
}: PolymorphicComponentProps<E> & TextProps) => {
  const Component = as || 'span'

  return (
    <Component
      {...restProps}
      className={cn(styles[kind], styles[kind + '-' + size], className)}
    >
      {children}
    </Component>
  )
}
