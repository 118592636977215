import { FC } from 'react'
// styles
import styles from './Link.module.scss'
// types
import { LinkProps, LinkVariants } from './types'

export const Link: FC<LinkProps> = ({
  children,
  variant = LinkVariants.primary,
  ...restProps
}) => {
  return (
    <a {...restProps} className={styles[variant + 'Link']}>
      {children}
    </a>
  )
}
