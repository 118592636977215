import { FC } from 'react'
// local libs
import { LanguageSelect } from '../LanguageSelect/LanguageSelect'
import { ThemeSelect } from '../ThemeSelect/ThemeSelect'
import { store } from 'src/app/store'
// styles
import styles from './Header.module.scss'
// types
import { HeaderProps } from './types'

export const Header: FC<HeaderProps> = ({ logoUrl }) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerLogo}>
          <img src={logoUrl} />
        </div>

        <div className={styles.headerSettings}>
          <LanguageSelect languageList={store.languageList.value} />
          <ThemeSelect />
        </div>
      </div>
    </div>
  )
}
