import { FC } from 'react'

export const ChevronUp: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M8 3L16 11L14.88 12.12L8 5.24L1.12 12.12L0 11L8 3Z" />
    </svg>
  )
}
