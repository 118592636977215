import { FC } from 'react'
import { ThemeProps } from '../themes.types'
import { generateCSSVariables } from '../../helpers'
import styles from '../../Theme.module.scss'

export const defaultDarkTheme: ThemeConfig = {
  body: {
    backgroundColor: '#161616',
    borderRadius: '0.75rem',
    focusBorderColor: '#4589ff',
    borderColor1: '#6f6f6f',
    borderColor2: '#8d8d8d',

    shadowColor1: 'rgba(22, 22, 22, 0.1)',
    shadowColor2: 'rgba(22, 22, 22, 0.25)',
    shadowDropColor1: 'rgba(22, 22, 22, 0.3)',
    shadowPrimary:
      '0px -1px 1px 0px var(--body-shadowColor1), 0px 1px 1px 0px var(--body-shadowColor2), 0px 2px 6px 0px var(--body-shadowDropColor1)',
  },
  input: {
    textColor: '#F4F4F4',
    backgroundColor: '#393939',
    activeBorderColor: '#4589ff',
    errorTextColor: '#ff8389',
    errorBorderColor: '#ff8389',
    placeholderTextColor: '#6f6f6f',
    borderRadius: '0.625rem',
  },
  paymentMethodCard: {
    backgroundColor: '#262626',
    titleColor: '#F4F4F4',
    captionColor: '#C6C6C6',
    cardMethodColor: '#33B1FF',
    p2pMethodColor: '#08BDBA',
    mobileCommerceMethodColor: '#EE5396',
    sbpMethodColor: '#A56EFF',
  },
  button: {
    primaryBackgroundColor: '#0F62FE',
    primaryBackgroundColorHover: '#0050E6',
    primaryBackgroundColorActive: '#002D9C',
    primaryTextColor: '#FFFFFF',

    secondaryBackgroundColor: 'transparent',
    secondaryBackgroundColorHover: '#F4F4F4',
    secondaryBackgroundColorActive: '#C6C6C6',
    secondaryTextColor: '#525252',
    secondaryBorderColor: '#C6C6C6',

    withShadowBackgroundColor: '#ffffff',
    withShadowBackgroundColorHover: '#F4F4F4',
    withShadowBackgroundColorActive: '#C6C6C6',
    withShadowTextColor: '#525252',
    withShadowBorderColor: '#8d8d8d',

    tertiaryBackgroundColor: 'transparent',
    tertiaryBackgroundColorHover: 'rgba(15, 98, 254, 0.05)',
    tertiaryBackgroundColorActive: 'rgba(15, 98, 254, 0.2)',
    tertiaryTextColor: '#ffffff',
    tertiaryBorderColor: '#0F62FE',

    ghostBackgroundColor: '#F4F4F4',
    ghostBackgroundColorHover: 'rgba(15, 98, 254, 0.05)',
    ghostBackgroundColorActive: 'rgba(15, 98, 254, 0.2)',
    ghostTextColor: '#0F62FE',

    dangerBackgroundColor: '#DA1E28',
    dangerBackgroundColorHover: '#B81922',
    dangerBackgroundColorActive: '#750E13',
    dangerTextColor: '#FFFFFF',

    disabledBackgroundColor: '#525252',
    disabledBorderColor: '#8d8d8d',
    disabledTextColor: 'rgba(244, 244, 244, 0.25)',

    borderRadius: '0.75rem',
  },
  text: {
    primary: '#f4f4f4',
    secondary: '#c6c6c6',
    basicInformation: '#0f62fe',
    helper: '#8D8D8D',
    disabled: 'rgba(244, 244, 244, 0.25)',
  },
  link: {
    primary: '#78a9ff',
    primaryHover: '#a6c8ff',
    secondary: '#c6c6c6',
    secondaryHover: '#f4f4f4',
    visited: '#be95ff',
  },
  icon: {
    primary: '#F4F4F4',
    secondary: '#c6c6c6',
    active: '#6f6f6f',
    danger: '#da1e28',
    buttonColor: '#ffffff',
    radioButton: '#4589ff',
    buttonColorDisabled: 'rgba(244, 244, 244, 0.25)',
    disabled: 'rgba(244, 244, 244, 0.25)',
  },
  savedCard: {
    borderColor: '#6F6F6F',
    dangerBorderColor: '#DA1E28',
    backgroundColor: '#393939',
    textColor: '#F4F4F4',
  },
  errorDisplay: {
    completedIconFill: '#24a148',
    contactIconFill: '#F1C21B',
  },
  themeSelect: {
    backgroundColor: '#393939',
    activeButtonColor: '#0f62fe',
    borderRadius: '0.75rem',
  },
  languageSelect: {
    itemBackgroundColor: '#161616',
    itemHoverBackgroundColor: '#393939',
    itemTextColor: '#78a9ff',
  },
  tooltip: {
    backgroundColor: '#f4f4f4',
    textColor: '#161616',
    borderRadius: '0.25rem',
  },
}

export const DarkTheme: FC<ThemeProps> = ({ variables }) => {
  return (
    <style>
      {`.${styles.darkTheme} {\n`}
      {generateCSSVariables({
        ...defaultDarkTheme,
        ...variables,
      })}
      {'}'}
    </style>
  )
}
