import { FC } from 'react'

export const ChevronLeft: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.875 5L6.875 0L7.575 0.7L3.275 5L7.575 9.3L6.875 10L1.875 5Z" />
    </svg>
  )
}
