import { FC } from 'react'
// local libs
import { Link, Text } from 'src/shared/ui'
import { ChevronLeft } from 'src/shared/assets/icons'
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { store } from 'src/app/store'
// styles
import styles from './Terms.module.scss'
// types
import { TextKind, TextSizes } from 'src/shared/ui/Text/types'
import { LinkVariants } from 'src/shared/ui/Link/types'

export const Terms: FC = () => {
  const { translate } = useTranslate()

  const handleBackToShop = () => {
    const redirectUrl = store.paymentData.value?.urls.redirect
    if (redirectUrl) window.location.href = redirectUrl
  }

  return (
    <div className={styles.termsContainer}>
      <Text as="div" className={styles.cancelBtn}>
        <Link onClick={handleBackToShop}>
          <ChevronLeft />
          {translate('Layout.cancelBtn')}
        </Link>
      </Text>

      <div className={styles.termsText}>
        <Text as="div" kind={TextKind.label} size={TextSizes.large}>
          {translate('Layout.terms1')}
          &nbsp;
          <Link variant={LinkVariants.secondary}>
            {translate('Layout.terms2')}
          </Link>
        </Text>
      </div>
    </div>
  )
}
