import { FC } from 'react'

export const WarningIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_835_965)">
        <path
          d="M24 0C19.2533 0 14.6131 1.40758 10.6663 4.04473C6.71954 6.68188 3.6434 10.4302 1.8269 14.8156C0.0103988 19.201 -0.464881 24.0266 0.461164 28.6822C1.38721 33.3377 3.67299 37.6141 7.02945 40.9706C10.3859 44.327 14.6623 46.6128 19.3178 47.5388C23.9734 48.4649 28.799 47.9896 33.1844 46.1731C37.5698 44.3566 41.3181 41.2805 43.9553 37.3337C46.5924 33.3869 48 28.7467 48 24C48 17.6348 45.4714 11.5303 40.9706 7.02944C36.4697 2.52856 30.3652 0 24 0ZM24 44.5714C19.9314 44.5714 15.9541 43.3649 12.5711 41.1045C9.18818 38.8441 6.55149 35.6313 4.99449 31.8723C3.43749 28.1134 3.0301 23.9772 3.82386 19.9867C4.61761 15.9962 6.57685 12.3308 9.45381 9.4538C12.3308 6.57684 15.9963 4.6176 19.9867 3.82384C23.9772 3.03009 28.1134 3.43747 31.8723 4.99448C35.6313 6.55148 38.8441 9.18817 41.1045 12.5711C43.3649 15.9541 44.5714 19.9314 44.5714 24C44.5714 29.4559 42.4041 34.6883 38.5462 38.5462C34.6883 42.4041 29.4559 44.5714 24 44.5714Z"
          fill="#DA1E28"
        />
        <path
          d="M25.7143 10.2857H22.2857V29.1429H25.7143V10.2857Z"
          fill="#DA1E28"
        />
        <path
          d="M24 34.2857C23.4914 34.2857 22.9943 34.4365 22.5714 34.7191C22.1485 35.0016 21.8189 35.4032 21.6243 35.8731C21.4297 36.343 21.3788 36.86 21.478 37.3588C21.5772 37.8576 21.8221 38.3158 22.1817 38.6754C22.5414 39.035 22.9995 39.2799 23.4983 39.3792C23.9972 39.4784 24.5142 39.4274 24.984 39.2328C25.4539 39.0382 25.8555 38.7086 26.1381 38.2857C26.4206 37.8629 26.5714 37.3657 26.5714 36.8571C26.5714 36.1752 26.3005 35.5211 25.8183 35.0389C25.336 34.5566 24.682 34.2857 24 34.2857Z"
          fill="#DA1E28"
        />
      </g>
      <defs>
        <clipPath id="clip0_835_965">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
