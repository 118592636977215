import { FC, Suspense, lazy, useEffect, useRef, useState } from 'react'
// local libs
import { useTranslate } from 'src/shared/hooks/useTranslate'
import { ChevronDown, ChevronUp } from 'src/shared/assets/icons'
import { Link, Text } from 'src/shared/ui'
// styles
import styles from './PaymentAmountCard.module.scss'
// types
import { PaymentAmountCardProps } from './types'
import { TextKind, TextSizes } from 'src/shared/ui/Text'

export const PaymentAmountCard: FC<PaymentAmountCardProps> = ({
  amount,
  currency,
  orderLink,
}) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [shouldShowTooltip, setShouldShowTooltip] = useState<boolean>(false)
  const { translate } = useTranslate()
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)

  const Tooltip = lazy(() => import('src/shared/ui/Tooltip'))

  useEffect(() => {
    const div = divRef.current
    div && setShouldShowTooltip(div.scrollWidth > div.clientWidth)
  }, [amount, currency])

  return (
    <div className={styles.paymentAmountCardContainer}>
      <div className={styles.paymentAmountCardHead}>
        <div
          ref={divRef}
          className={styles.paymentAmountCardTitle}
          data-tooltip-id="amount"
        >
          <Text kind={TextKind.headline} size={TextSizes.medium}>
            {amount + ' ' + currency}
          </Text>
        </div>

        <Suspense>
          {shouldShowTooltip && (
            <Tooltip tooltipId="amount">{amount + ' ' + currency}</Tooltip>
          )}
        </Suspense>

        <button onClick={toggleOpen} className={styles.paymentAmountCardButton}>
          {isOpen ? (
            <ChevronUp className={styles.paymentAmountCardChevron} />
          ) : (
            <ChevronDown className={styles.paymentAmountCardChevron} />
          )}
        </button>
      </div>

      {isOpen && (
        <Text
          className={styles.paymentAmountOrderInfo}
          as="div"
          kind={TextKind.label}
          size={TextSizes.large}
        >
          {translate('Layout.paymentOrderInfoText')}
          <Link href={orderLink}>
            {translate('Layout.paymentOrderInfoLinkText')}
          </Link>
        </Text>
      )}
    </div>
  )
}
