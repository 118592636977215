const path = document.location.href
const regex = /\/open\/([a-zA-Z0-9-]+)/
const match = path.match(regex)

export const userSession = match ? match[1] : null

export let API_URL = ''

// for local dev
if (!import.meta.env.PROD) {
  API_URL = 'https://payment-terminal-form.dev.idynsys.org'
}

export const api = {
  paymentData: `${API_URL}/api/open/${userSession}`,
  config: `${API_URL}/api/config`,
  languages: `${API_URL}/api/localizer/languages`,
  translations: `${API_URL}/api/localizer/translations`,
  cards: `${API_URL}/api/billing/bank-cards`,
  deposit: `${API_URL}/api/billing/deposit`,
  transactionStatus: `${API_URL}/api/billing/transaction/status`,
} as const
