import { FC } from 'react'

export const MoonIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9837 7.99556C11.08 10.391 8.7525 12 6.19124 12C2.77748 12 0 9.22202 0 5.80769C0 3.24478 1.60876 0.916875 4.0025 0.0154685C4.09499 -0.0182815 4.19873 0.00421845 4.26751 0.0729841C4.30176 0.107091 4.32541 0.15038 4.33561 0.197631C4.34582 0.244882 4.34214 0.294076 4.32501 0.339281C4.0825 0.979383 3.95877 1.65577 3.95877 2.35085C3.95877 5.4889 6.51127 8.04307 9.65003 8.04307C10.3463 8.04307 11.0225 7.91806 11.66 7.67426C11.7525 7.63927 11.8563 7.6605 11.9263 7.73053C11.9962 7.7993 12.0187 7.9043 11.9837 7.99556Z"
        fill="#161616"
      />
    </svg>
  )
}
