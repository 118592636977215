const iso6391to6392 = {
  en: 'eng', // English
  es: 'spa', // Spanish
  zh: 'zho', // Chinese
  hi: 'hin', // Hindi
  ar: 'ara', // Arabic
  pt: 'por', // Portuguese
  bn: 'ben', // Bengali
  ru: 'rus', // Russian
  ja: 'jpn', // Japanese
  de: 'deu', // German
  ko: 'kor', // Korean
  fr: 'fra', // French
  tr: 'tur', // Turkish
  it: 'ita', // Italian
  pl: 'pol', // Polish
  nl: 'nld', // Dutch
  th: 'tha', // Thai
  vi: 'vie', // Vietnamese
  id: 'ind', // Indonesian
  fa: 'fas', // Persian
  ms: 'msa', // Malay
  ta: 'tam', // Tamil
  ur: 'urd', // Urdu
  el: 'ell', // Greek
  he: 'heb', // Hebrew
  uk: 'ukr', // Ukrainian
  hu: 'hun', // Hungarian
  sv: 'swe', // Swedish
  fi: 'fin', // Finnish
  no: 'nor', // Norwegian
  da: 'dan', // Danish
  cs: 'ces', // Czech
  sk: 'slk', // Slovak
  ro: 'ron', // Romanian
  bg: 'bul', // Bulgarian
  hr: 'hrv', // Croatian
  sr: 'srp', // Serbian
  sl: 'slv', // Slovenian
  mk: 'mkd', // Macedonian
  lv: 'lav', // Latvian
  lt: 'lit', // Lithuanian
  et: 'est', // Estonian
  az: 'aze', // Azerbaijani
  ka: 'kat', // Georgian
  hy: 'hye', // Armenian
  kk: 'kaz', // Kazakh
  uz: 'uzb', // Uzbek
  tk: 'tuk', // Turkmen
  tg: 'tgk', // Tajik
  am: 'amh', // Amharic
  my: 'mya', // Burmese
  sw: 'swa', // Swahili
  af: 'afr', // Afrikaans
  sq: 'sqi', // Albanian
  eu: 'eus', // Basque
  be: 'bel', // Belarusian
  bs: 'bos', // Bosnian
  cy: 'cym', // Welsh
  eo: 'epo', // Esperanto
  gl: 'glg', // Galician
  ha: 'hau', // Hausa
  is: 'isl', // Icelandic
  ig: 'ibo', // Igbo
  ga: 'gle', // Irish
  kn: 'kan', // Kannada
  lo: 'lao', // Lao
  la: 'lat', // Latin
  lb: 'ltz', // Luxembourgish
  mg: 'mlg', // Malagasy
  ml: 'mal', // Malayalam
  mn: 'mon', // Mongolian
  ne: 'nep', // Nepali
  pa: 'pan', // Punjabi
  si: 'sin', // Sinhala
  so: 'som', // Somali
  su: 'sun', // Sundanese
  tl: 'tgl', // Tagalog
  te: 'tel', // Telugu
  yo: 'yor', // Yoruba
  zu: 'zul', // Zulu
  xh: 'xho', // Xhosa
  st: 'sot', // Sotho
  tn: 'tsn', // Tswana
  ts: 'tso', // Tsonga
  ve: 'ven', // Venda
  nr: 'nbl', // South Ndebele
  sn: 'sna', // Shona
  ny: 'nya', // Chichewa
  rw: 'kin', // Kinyarwanda
  om: 'orm', // Oromo
  km: 'khm', // Khmer
  lg: 'lug', // Ganda
}

export const getLangCode = (iso6391: string) =>
  iso6391to6392[iso6391 as keyof typeof iso6391to6392] || iso6391
