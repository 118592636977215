export const validationRequiredKey = 'Frontend Validation Messages.required'
export const validationMinLengthKey = 'Frontend Validation Messages.minLength'
export const validationMaxLengthKey = 'Frontend Validation Messages.maxLength'
export const validationInvalidFormatKey =
  'Frontend Validation Messages.invalidFormat'
export const validationExpiredKey =
  'UI.Payment terminal.Frontend Validation Messages.cardExpired'
export const validationExceedKey =
  'UI.Payment terminal.Frontend Validation Messages.cardExceed'
export const validationNoLeadingSpacesKey =
  'Frontend Validation Messages.noLeadingSpaces'
