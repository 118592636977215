import { Routes, Route } from 'react-router-dom'
// local libs
// import { BankCardMethod } from 'src/paymentMethods/BankCardMethod';
import { PaymentMethods } from '../Layout/PaymentMethods'
import { ErrorDisplay, ContactPageRedirect } from 'src/shared/ui'
import { userSession } from '../config/api'

export enum PagesEnum {
  success = '/payment-success',
  error = '/payment-error',
  contactStore = '/contact-store',
}

export const AppRoutes = () => {
  return (
    <Routes>
      {userSession && (
        <Route
          path="/open/:hash"
          element={<PaymentMethods />}
          errorElement={<ErrorDisplay variant="contact" />}
        />
      )}

      <Route
        path={PagesEnum.success}
        element={<ErrorDisplay variant="completed" />}
      />
      <Route
        path={PagesEnum.error}
        element={<ErrorDisplay variant="error" />}
      />
      <Route
        path={PagesEnum.contactStore}
        element={<ErrorDisplay variant="contact" />}
      />

      <Route path="*" element={<ContactPageRedirect />} />
    </Routes>
  )
}
