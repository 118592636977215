export function generateCSSVariables(
  obj: Record<string, unknown>,
  prefix = '',
) {
  let cssVariables = ''
  for (const [key, value] of Object.entries(obj)) {
    const currentPrefix = prefix ? `${prefix}-${key}` : key
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      const subVariables = generateCSSVariables(
        value as Record<string, unknown>,
        currentPrefix,
      )
      cssVariables += `${subVariables}`
    } else {
      cssVariables += `--${currentPrefix}: ${value};\n`
    }
  }
  return cssVariables
}
