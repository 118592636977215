import { FC } from 'react'

export const ErrorXIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_794_4038)">
        <path
          d="M24 0C10.6286 0 0 10.6286 0 24C0 37.3714 10.6286 48 24 48C37.3714 48 48 37.3714 48 24C48 10.6286 37.3714 0 24 0ZM24 44.5714C12.6857 44.5714 3.42857 35.3143 3.42857 24C3.42857 12.6857 12.6857 3.42857 24 3.42857C35.3143 3.42857 44.5714 12.6857 44.5714 24C44.5714 35.3143 35.3143 44.5714 24 44.5714Z"
          fill="#DA1E28"
        />
        <path
          d="M33.2571 36L24 26.7429L14.7429 36L12 33.2571L21.2571 24L12 14.7429L14.7429 12L24 21.2571L33.2571 12L36 14.7429L26.7429 24L36 33.2571L33.2571 36Z"
          fill="#DA1E28"
        />
      </g>
      <defs>
        <clipPath id="clip0_794_4038">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
