// types
import { ThemeEnum } from 'src/shared/types/enums'

export function getInitTheme(): ThemeEnum {
  const storedTheme = window?.localStorage.getItem('themeValue') as ThemeEnum

  if (storedTheme) {
    return ThemeEnum[storedTheme] || ThemeEnum.light
  }

  const systemTheme = window?.matchMedia('(prefers-color-scheme: dark)')
    ? ThemeEnum.dark
    : ThemeEnum.light
  window?.localStorage.setItem('themeValue', systemTheme)

  return systemTheme
}
