import axios from 'axios'
// local libs
import { api, userSession } from '../api'
import { DEFAULT_LANG_CODE, PRODUCT_NAME } from '../consts'
import fallbackTranslations from './payment-terminal_translations.json'
// types
import { SuccessDataResponse } from 'src/shared/hooks/useResponseHandler'
import { Resources, Translations } from './types'

export const getLanguages = async () => {
  const langsRes = await axios.request<SuccessDataResponse<Array<Language>>>({
    url: `${api.languages}?hash=${userSession}`,
  })
  return langsRes.data.data
}

export const getResources = async () => {
  const translationsRes = await axios.request<
    SuccessDataResponse<Translations>
  >({
    url: `${api.translations}?hash=${userSession}`,
  })
  const translations = translationsRes.data.data
  const terminalTranslations = translations.find(
    (x) => x.product_name === PRODUCT_NAME,
  )
  if (!terminalTranslations) throw new Error()

  const langTranslations = terminalTranslations.translations
  const resources = Object.entries(langTranslations).reduce<Resources>(
    (acc, [code, trans]) => {
      acc[code] = { [PRODUCT_NAME]: trans }
      return acc
    },
    {},
  )

  return resources
}

export const getFallbackResources = () => {
  const resourses = fallbackTranslations.reduce<Resources>(
    (acc, { language_code, parent_name, item_name, translation }) => {
      if (language_code === DEFAULT_LANG_CODE)
        acc[DEFAULT_LANG_CODE][PRODUCT_NAME][`${parent_name}.${item_name}`] =
          translation
      return acc
    },
    { [DEFAULT_LANG_CODE]: { [PRODUCT_NAME]: {} } },
  )

  return resourses
}

export const fallbackLanguages: Array<Language> = [
  { code: DEFAULT_LANG_CODE, name: 'Английский' },
]
