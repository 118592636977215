import { FC, PropsWithChildren } from 'react'
// local libs
import { Header } from './Header'
import { Footer } from './Footer'
import { Terms } from './Terms'
import { LAYOUT_ID } from 'src/shared/constants'
import { useCustomConfig } from './Theme/helpers'
// styles
import styles from './Layout.module.scss'

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { backgroundUrl, logoUrl } = useCustomConfig()

  return (
    <div className={styles.layoutContainer}>
      {backgroundUrl && (
        <div
          className={styles.fixedBackground}
          style={{ backgroundImage: backgroundUrl }}
        />
      )}

      <div id={LAYOUT_ID} className={styles.layoutWrapper}>
        <Header logoUrl={logoUrl} />
        <div className={styles.pageContent}>
          {children}
          <Terms />
        </div>
        <Footer />
      </div>
    </div>
  )
}
