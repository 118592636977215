import { FC } from 'react'

export const SunIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99999 8.99996C7.65684 8.99996 8.99998 7.65683 8.99998 5.99998C8.99998 4.34314 7.65684 3 5.99999 3C4.34314 3 3 4.34314 3 5.99998C3 7.65683 4.34314 8.99996 5.99999 8.99996Z"
        fill="#161616"
      />
      <path
        d="M6.00002 2.14287C6.2367 2.14287 6.42859 1.95098 6.42859 1.7143V0.428574C6.42859 0.191892 6.2367 0 6.00002 0C5.76334 0 5.57144 0.191892 5.57144 0.428574V1.7143C5.57144 1.95098 5.7633 2.14287 6.00002 2.14287ZM2.66645 3.27242C2.83669 3.43684 3.10801 3.43214 3.27243 3.2619C3.43282 3.09583 3.43282 2.8325 3.27243 2.66644L2.3634 1.75741C2.19316 1.59299 1.92183 1.59769 1.75741 1.76793C1.59702 1.93403 1.59702 2.19733 1.75741 2.36339L2.66645 3.27242ZM2.14288 6C2.14288 5.76332 1.95098 5.57143 1.7143 5.57143H0.428575C0.191892 5.57143 0 5.76328 0 6C0 6.23672 0.191892 6.42857 0.428575 6.42857H1.7143C1.95098 6.42857 2.14288 6.23668 2.14288 6ZM2.66645 8.72754L1.75741 9.63657C1.59299 9.80681 1.5977 10.0781 1.76794 10.2426C1.934 10.4029 2.19734 10.4029 2.3634 10.2426L3.27243 9.33352C3.43685 9.16328 3.43215 8.89196 3.26191 8.72754C3.09581 8.56715 2.83251 8.56715 2.66645 8.72754ZM6.00002 9.85713C5.76334 9.85713 5.57144 10.049 5.57144 10.2857V11.5714C5.57144 11.8081 5.76334 12 6.00002 12C6.2367 12 6.42859 11.8081 6.42859 11.5714V10.2857C6.42859 10.049 6.2367 9.85713 6.00002 9.85713ZM9.33355 8.72754C9.16331 8.56312 8.89199 8.56782 8.72757 8.73807C8.56718 8.90413 8.56718 9.16743 8.72757 9.33352L9.6366 10.2426C9.80684 10.407 10.0782 10.4023 10.2426 10.232C10.403 10.066 10.403 9.80267 10.2426 9.63657L9.33355 8.72754ZM11.5714 5.57143H10.2857C10.049 5.57143 9.85712 5.76332 9.85712 6C9.85712 6.23668 10.049 6.42857 10.2857 6.42857H11.5714C11.8081 6.42857 12 6.23668 12 6C12 5.76332 11.8081 5.57143 11.5714 5.57143ZM9.33355 3.27242L10.2426 2.36339C10.4128 2.19897 10.4176 1.92765 10.2531 1.75741C10.0887 1.58716 9.81737 1.58242 9.64713 1.74688C9.64354 1.75032 9.64004 1.75386 9.6366 1.75741L8.72757 2.66644C8.56315 2.83668 8.56785 3.108 8.73809 3.27242C8.90419 3.43285 9.16749 3.43285 9.33355 3.27242Z"
        fill="#161616"
      />
    </svg>
  )
}
