import { ReactNode, lazy } from 'react'
// local libs
import { PaymentAmountCard, ContactPageRedirect } from 'src/shared/ui'
import { store } from 'src/app/store'
import { isMobileDevice } from 'src/shared/utils'
// styles
import styles from './PaymentMethods.module.scss'
// types
import { MethodComponents, MethodNameEnum } from './types'

const BankCardMethod = lazy(() => import('src/paymentMethods/BankCardMethod'))
const P2P_H2H = lazy(() => import('src/paymentMethods/P2PMethods/P2P_H2H'))
const SBPMethod = lazy(() => import('src/paymentMethods/SBPMethod/SBPMethod'))
const SBPMethodQr = lazy(
  () => import('src/paymentMethods/SBPMethod/SBPMethodQr'),
)
const MobileCommerceMethod = lazy(
  () => import('src/paymentMethods/MobileCommerceMethod'),
)
const P2PMethod = lazy(() => import('src/paymentMethods/P2PMethods/P2PMethod'))
const SberPayMethod = lazy(() => import('src/paymentMethods/SberPayMethod'))

const methodComponents: MethodComponents = {
  [MethodNameEnum.Bankcard]: BankCardMethod,
  [MethodNameEnum.P2P]: P2P_H2H,
  [MethodNameEnum.P2PWithoutBank]: P2PMethod,
  [MethodNameEnum.SBP]: isMobileDevice() ? SBPMethod : SBPMethodQr,
  [MethodNameEnum.MobileCommerce]: MobileCommerceMethod,
  [MethodNameEnum.SberPay]: SberPayMethod,
}

export const PaymentMethods = () => {
  // ожидаем подгруздки данных
  if (!store.paymentData.value?.paymentInfo) {
    return <></>
  }

  const mappedMethods: Array<ReactNode> = []

  for (const paymentMethod of store.paymentData.value.availablePaymentMethods) {
    const Component = methodComponents[paymentMethod.name as MethodNameEnum]
    if (Component) {
      const disabled =
        store.activePaymentMethod.value &&
        store.activePaymentMethod.value !== paymentMethod.name
      mappedMethods.push(
        <Component key={paymentMethod.name} disabled={Boolean(disabled)} />,
      )
    }
  }

  // если не находим в списке методов зашитые поддержанные на фронте, то редирект
  if (!mappedMethods.length) {
    return <ContactPageRedirect />
  }

  const { amount, currency } = store.paymentData.value.paymentInfo
  const { redirect } = store.paymentData.value.urls

  return (
    <div>
      <PaymentAmountCard
        amount={amount}
        currency={currency}
        orderLink={redirect}
      />

      <div className={styles.paymentMethods}>{mappedMethods}</div>
    </div>
  )
}
