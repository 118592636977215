import { FC } from 'react'
// local libs
import { AppRoutes } from '../routes'
import { store } from '../store'
import { Layout, ThemeProvider } from '../Layout'
import { useAppInit } from './useAppInit'

export const App: FC = () => {
  const { isAppInitiated } = useAppInit()

  return !isAppInitiated ? (
    <></>
  ) : (
    <ThemeProvider theme={store.paymentData.value?.terminalConfig.theme}>
      <Layout>
        <AppRoutes />
      </Layout>
    </ThemeProvider>
  )
}
