import { FC, PropsWithChildren, createContext, useState } from 'react'
import cn from 'classnames'
// local libs
import { ThemeContextProps, ThemeProviderProps } from './Theme.types'
import { DarkTheme, LightTheme } from './themes'
import { getInitTheme } from './helpers'
import styles from './Theme.module.scss'
// types
import { ThemeEnum } from 'src/shared/types/enums'

const initialThemeContextState: ThemeContextProps = {
  themeValue: ThemeEnum.light,
  toggleTheme: () => {},
}

export const ThemeContext = createContext<ThemeContextProps>(
  initialThemeContextState,
)

export const ThemeProvider: FC<PropsWithChildren<ThemeProviderProps>> = ({
  children,
  theme,
  initTheme,
}) => {
  const [themeValue, setTheme] = useState<ThemeEnum>(
    initTheme || getInitTheme(),
  )

  const toggleTheme = () =>
    setTheme((prevTheme) => {
      const newTheme =
        prevTheme === ThemeEnum.light ? ThemeEnum.dark : ThemeEnum.light
      window?.localStorage.setItem('themeValue', newTheme)
      return newTheme
    })

  const CurrentTheme = {
    [ThemeEnum.light]: LightTheme,
    [ThemeEnum.dark]: DarkTheme,
  }[themeValue]

  return (
    <ThemeContext.Provider value={{ themeValue, toggleTheme }}>
      <CurrentTheme variables={theme?.[themeValue] || {}} />
      <div
        className={cn({
          [styles.darkTheme]: themeValue === ThemeEnum.dark,
          [styles.lightTheme]: themeValue === ThemeEnum.light,
        })}
      >
        {children}
      </div>
    </ThemeContext.Provider>
  )
}
