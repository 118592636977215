import { FC } from 'react'

export const CheckMark: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5714 33.2811L12 24.708L14.4223 22.2857L20.5714 28.4331L33.5743 15.4286L36 17.8543L20.5714 33.2811Z"
        fill="#0F62FE"
      />
      <path
        d="M24 0C19.2533 0 14.6131 1.40758 10.6663 4.04473C6.71954 6.68188 3.6434 10.4302 1.8269 14.8156C0.0103988 19.201 -0.464881 24.0266 0.461164 28.6822C1.38721 33.3377 3.67299 37.6141 7.02945 40.9706C10.3859 44.327 14.6623 46.6128 19.3178 47.5388C23.9734 48.4649 28.799 47.9896 33.1844 46.1731C37.5698 44.3566 41.3181 41.2805 43.9553 37.3337C46.5924 33.3869 48 28.7467 48 24C48 17.6348 45.4714 11.5303 40.9706 7.02944C36.4697 2.52856 30.3652 0 24 0ZM24 44.5714C19.9314 44.5714 15.9541 43.3649 12.5711 41.1045C9.18818 38.8441 6.55149 35.6313 4.99449 31.8723C3.43749 28.1134 3.0301 23.9772 3.82386 19.9867C4.61761 15.9962 6.57685 12.3308 9.45381 9.4538C12.3308 6.57684 15.9963 4.6176 19.9867 3.82384C23.9772 3.03009 28.1134 3.43747 31.8723 4.99448C35.6313 6.55148 38.8441 9.18817 41.1045 12.5711C43.3649 15.9541 44.5714 19.9314 44.5714 24C44.5714 29.4559 42.4041 34.6883 38.5462 38.5462C34.6883 42.4041 29.4559 44.5714 24 44.5714Z"
        fill="#0F62FE"
      />
    </svg>
  )
}
